<template>
  <div class="page-user-reading">
    <UserReadingChart />
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import UserReadingChart from '@/views/stats/user-reading-chart.vue'

export default defineComponent({
  name: 'UserReading',
  components: { UserReadingChart },
  setup() {
    return {}
  }
})
</script>
<style lang="scss">
.page-user-reading {
  position: relative;
}
</style>
