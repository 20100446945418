<template>
  <div class="component-base-stats">
    <div class="businessline-wrap">
      <div class="legend-conf">
        <div class="conf-L">
          <el-date-picker
            format="YYYY-MM-DD HH:mm"
            v-model="refBetweenTime"
            type="datetimerange"
            :shortcuts="shortcuts"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
          <span>&nbsp;&nbsp;</span>
          <el-radio-group v-model="refTimeScale">
            <el-radio label="day">日</el-radio>
            <el-radio label="week">周</el-radio>
            <el-radio label="month">月</el-radio>
          </el-radio-group>
        </div>
        <div class="conf-R"></div>
      </div>
      <div class="charts-body" style="width: 100%" :id="chartId"></div>
      <div class="legend-line">
        <el-checkbox-group v-model="refCombineTypeList">
          <div>
            <h5>总数</h5>
            <el-checkbox label="total_read_v">阅读篇数</el-checkbox>
            <el-checkbox label="total_read_uv">阅读人数</el-checkbox>
            <el-checkbox label="total_read_pv">阅读次数</el-checkbox>
          </div>
          <div>
            <h5>按发文类型</h5>
            <el-checkbox label="total_read_long_v">阅读篇数(文章)</el-checkbox>
            <el-checkbox label="total_read_long_uv">阅读人数(文章)</el-checkbox>
            <el-checkbox label="total_read_long_pv">阅读次数(文章)</el-checkbox>
            <br />
            <el-checkbox label="total_read_short_v">阅读篇数(帖子)</el-checkbox>
            <el-checkbox label="total_read_short_uv">阅读人数(帖子)</el-checkbox>
            <el-checkbox label="total_read_short_pv">阅读次数(帖子)</el-checkbox>
          </div>
          <div>
            <h5>按平台</h5>
            <el-checkbox label="total_read_pc_v">阅读篇数(PC)</el-checkbox>
            <el-checkbox label="total_read_pc_uv">阅读人数(PC)</el-checkbox>
            <el-checkbox label="total_read_pc_pv">阅读次数(PC)</el-checkbox>
            <br />
            <el-checkbox label="total_read_app_v">阅读篇数(APP)</el-checkbox>
            <el-checkbox label="total_read_app_uv">阅读人数(APP)</el-checkbox>
            <el-checkbox label="total_read_app_pv">阅读次数(APP)</el-checkbox>
            <br />
            <el-checkbox label="total_read_ios_v">阅读篇数(IOS)</el-checkbox>
            <el-checkbox label="total_read_ios_uv">阅读人数(IOS)</el-checkbox>
            <el-checkbox label="total_read_ios_pv">阅读次数(IOS)</el-checkbox>
            <br />
            <el-checkbox label="total_read_android_v">阅读篇数(Android)</el-checkbox>
            <el-checkbox label="total_read_android_uv">阅读人数(Android)</el-checkbox>
            <el-checkbox label="total_read_android_pv">阅读次数(Android)</el-checkbox>
          </div>
        </el-checkbox-group>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, onMounted, watch } from 'vue'
import { format } from 'date-fns'
import cloneDeep from 'lodash/cloneDeep'
import { fetchContentStats } from '@/domains/stats/api'
import type { StatContentData } from '@/domains/stats/models/stats'
import { shortcuts, TimeScale, defaultBetweenTime, calcxAxis, useHandleChartsInit } from '@/views/stats/constant'

type CombineType =
  | 'total_read_v'
  | 'total_read_uv'
  | 'total_read_pv'
  | 'total_read_long_v'
  | 'total_read_long_uv'
  | 'total_read_long_pv'
  | 'total_read_short_v'
  | 'total_read_short_uv'
  | 'total_read_short_pv'
  | 'total_read_pc_v'
  | 'total_read_pc_uv'
  | 'total_read_pc_pv'
  | 'total_read_ios_v'
  | 'total_read_ios_uv'
  | 'total_read_ios_pv'
  | 'total_read_app_v'
  | 'total_read_app_uv'
  | 'total_read_app_pv'
  | 'total_read_android_v'
  | 'total_read_android_uv'
  | 'total_read_android_pv'

const defaultChartData = {
  title: {
    text: ''
  },
  tooltip: {
    trigger: 'axis'
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true
  },
  toolbox: {
    feature: {
      // saveAsImage: {},
    }
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: []
  },
  yAxis: {
    type: 'value'
  },
  series: []
}

const combineTypeMap: { [key: string]: string } = {
  total_read_v: '阅读篇数',
  total_read_uv: '阅读人数',
  total_read_pv: '阅读次数',
  total_read_long_v: '阅读篇数(文章)',
  total_read_long_uv: '阅读人数(文章)',
  total_read_long_pv: '阅读次数(文章)',
  total_read_short_v: '阅读篇数(帖子)',
  total_read_short_uv: '阅读人数(帖子)',
  total_read_short_pv: '阅读次数(帖子)',
  total_read_pc_v: '阅读篇数(PC)',
  total_read_pc_uv: '阅读人数(PC)',
  total_read_pc_pv: '阅读次数(PC)',
  total_read_ios_v: '阅读篇数(IOS)',
  total_read_ios_uv: '阅读人数(IOS)',
  total_read_ios_pv: '阅读次数(IOS)',
  total_read_app_v: '阅读篇数(APP)',
  total_read_app_uv: '阅读人数(APP)',
  total_read_app_pv: '阅读次数(APP)',
  total_read_android_v: '阅读篇数(Android)',
  total_read_android_uv: '阅读人数(Android)',
  total_read_android_pv: '阅读次数(Android)'
}

interface BodyParams {
  article_type: Array<'long' | 'short'>
  behavior_type: Array<string>
  platform: Array<'android' | 'mweb' | 'pc' | 'ios'>
  key: string
}
function calcParamsByCombineType(type: CombineType): BodyParams {
  const body: BodyParams = {
    article_type: ['long', 'short'],
    behavior_type: ['BrowseArticleDetail'],
    platform: ['android', 'mweb', 'pc', 'ios'],
    key: 'resource_v'
  }
  if (/_uv/.test(type)) {
    body.key = 'uv'
  }
  if (/_pv/.test(type)) {
    body.key = 'pv'
  }
  if (/_short/.test(type)) {
    body.article_type = ['short']
  }
  if (/_long/.test(type)) {
    body.article_type = ['long']
  }
  if (/_pc/.test(type)) {
    body.platform = ['pc']
  }
  if (/_app/.test(type)) {
    body.platform = ['ios', 'android']
  }
  if (/_ios/.test(type)) {
    body.platform = ['ios']
  }
  if (/_android/.test(type)) {
    body.platform = ['android']
  }
  return body
}

export default defineComponent({
  name: 'BaseStats',
  props: {
    content_type: {
      type: String,
      default: 'generate'
    }
  },
  setup(props, ctx) {
    const fetchMethod = fetchContentStats
    const chartId = `XGB-${Math.random().toString(36).slice(6).toUpperCase()}`
    const chartIns = useHandleChartsInit(`#${chartId}`, 'dark')
    const refBetweenTime = ref<Date[]>(defaultBetweenTime)
    const refCombineTypeList = ref<CombineType[]>(['total_read_uv'])
    const refTimeScale = ref<TimeScale>('day')
    const renderChartData = cloneDeep(defaultChartData)
    let refCacheLines: any[] = []

    const clearCacheLine = () => {
      refCacheLines = []
    }

    // 拉取单个组合类型combineType
    const updateStatsCacheByCombineType = async (combineType: CombineType = 'total_read_uv') => {
      const [start, end] = refBetweenTime.value
      const params = {
        period: refTimeScale.value,
        start: Math.round(start.getTime() / 1000),
        end: Math.round(end.getTime() / 1000)
      }
      const paddingParams = calcParamsByCombineType(combineType)
      Object.assign(params, paddingParams)
      if (refCacheLines.find((line) => line.cacheid === `${combineType}`)) return
      const api = fetchMethod(params).then((res: StatContentData) => {
        if (res.items && res.items.length) {
          res.items.sort((L, R) => L.timestamp - R.timestamp)
          const lines = res.items.map((item) => {
            const axis_text = format(item.timestamp * 1000, 'MM-dd')
            const axis_val = item[paddingParams.key]
            return [axis_text, axis_val]
          })
          refCacheLines.push({
            name: `${combineTypeMap[combineType]}`,
            cacheid: `${combineType}`,
            expires: Date.now() + 600 * 1000,
            type: 'line',
            data: lines
          })
        }
      })
      return api
    }

    // 更新整个组合类型列表
    const updateStatsFromCache = (combineTypeList: CombineType[]) => {
      const cacheIdMap: { [key: string]: boolean } = {}
      combineTypeList.forEach((type) => {
        cacheIdMap[type] = true
      })
      const series = refCacheLines.filter((line) => {
        return cacheIdMap[line.cacheid] === true
      })
      const isAdd = series.length > renderChartData.series.length
      renderChartData.series = series as never[]
      renderChartData.xAxis.data = calcxAxis(series) as never[]
      chartIns.value.setOption(cloneDeep(renderChartData), !isAdd)
    }

    const updateAsyncByCombineTypeList = () => {
      const updateQueue: Promise<any>[] = []
      refCombineTypeList.value.forEach((type: any) => {
        updateQueue.push(updateStatsCacheByCombineType(type))
      })
      Promise.all(updateQueue)
        .then(() => {
          updateStatsFromCache(refCombineTypeList.value)
        })
        .catch((err) => {
          console.log('watch存在API拉取失败')
          updateStatsFromCache(refCombineTypeList.value)
        })
    }

    onMounted(() => {
      updateAsyncByCombineTypeList()
    })

    watch(refCombineTypeList, (newTypes, oldTypes) => {
      if (newTypes.length === 0) {
        console.log('refCombineTypeList-清空')
        renderChartData.series = []
        chartIns.value.setOption(cloneDeep(renderChartData), true)
        return
      }
      updateAsyncByCombineTypeList()
    })

    watch([refBetweenTime, refTimeScale], () => {
      if (!refBetweenTime.value) {
        renderChartData.series = []
        chartIns.value.setOption(cloneDeep(renderChartData), true)
        return
      }
      clearCacheLine()
      updateAsyncByCombineTypeList()
    })
    return {
      chartId,
      shortcuts,
      combineTypeMap,
      // reactive vars
      refBetweenTime,
      refCombineTypeList,
      refTimeScale
    }
  }
})
</script>
<style lang="scss">
.component-base-stats {
  position: relative;
  .legend-conf {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 20px 10px;
    width: 100%;
    .conf-R {
      margin-left: 10px;
    }
  }
  .charts-body {
    min-height: 500px;
    width: 100%;
  }
  .legend-line {
    font-size: 16px;
    margin: 20px 0;
    padding: 0 20px;
    .el-checkbox {
      min-width: 160px;
    }
    h5 {
      font-size: 16px;
      margin-top: 10px;
    }
  }
}
</style>
